html {
  scroll-snap-type: y proximity;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #39495c !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* ag grid global styles */

.ag-header-cell .ag-header-cell-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.centered-columns .ag-header-cell-comp-wrapper,
.centered-columns .ag-header-cell-label {
  justify-content: center;
}

.ag-center-cols-clipper,
.ag-center-cols-container {
  min-height: 50px !important;
}

.ag-header-icon.ag-header-label-icon.ag-filter-icon{
  color:red
}

.highlightedHeader,
.highlightedHeader:hover {
  background-color: #39495c !important;
  color: #ececec;
}

.universeHighlightedHeader,
.universeHighlightedHeader:hover {
  background-color: #92acca !important;
}

.highlightedHeader .ag-header-cell-resize::after {
  background-color: #636378;
}

.altHighlightedHeader {
  background-color: #e4e4e4 !important;
}

.altHighlightedHeader .ag-header-cell-resize::after {
  background-color: #bbb;
}

.highlightedCell {
  background-color: rgba(0, 0, 0, 0.045);
  border-color: rgba(0, 0, 0, 0.03) !important;
}

.ag-theme-alpine .ag-row-odd {
  background-color: #f9f9f9;
}

.footerRow {
  background-color: #ddd !important;
  font-weight: bold !important;
}

.editableCellContent {
  cursor: text;
}

.disabledCellContent,
.disabledRow .ag-cell,
.disabledRow .ant-select-selection-item {
  color: #b0b0b0;
  pointer-events: none;
}

.disabledRow .ag-cell.alwaysEnabledCell {
  pointer-events: all;
}

.disabledCellContent:not(.alwaysEnabledCell) .ant-switch,
.disabledRow .ag-cell:not(.alwaysEnabledCell) .ant-switch {
  opacity: 0.4;
}

.ant-switch-disabled,
.ant-switch-loading,
.ant-switch-disabled *,
.ant-switch-loading * {
  cursor: default !important;
}

.ant-select-dropdown .anticon.anticon-check {
  margin-left: 10px;
}

.switchContainer {
  text-align: center;
}

/* Chrome, Safari, Edge, Opera */
.ag-cell input::-webkit-outer-spin-button,
.ag-cell input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.ag-cell input[type='number'] {
  -moz-appearance: textfield;
}

.ag-cell .ant-input-number-handler-wrap {
  display: none;
}

.ant-custom-dropdown.ant-select-dropdown {
  min-width: 200px !important;
  border: 1px solid #bdc3c7;
  background-color: #f5f7f7;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
}

.ant-custom-option.ant-select-item {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 12px;
  min-height: 24px;
  margin-bottom: 1px;
}

.ant-custom-option.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgba(0, 145, 234, 0.28);
  font-weight: normal;
}

.ant-custom-option.ant-select-item-option:hover {
  background-color: rgba(0, 145, 234, 0.28);
}

.styledDropdown .rc-virtual-list .rc-virtual-list-scrollbar {
  display: block !important;
}
